<template>
	<div class="">
		<el-card>
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-select v-model="searchType" style="width: 150px;margin-right: 10px;">
						<el-option v-for="item in goodsSelect" :key="item.id" :label="item.value" :value="item.id">
						</el-option>
					</el-select>
					<el-input v-model="searchKey" placeholder="手机号、微信昵称、姓名" @keyup.enter.native="handlefilter" style="width: 250px;;margin-right: 10px;"
					clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">授权时间: </label>
					<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd HH:mm:ss" default-time='00:00:00'></el-date-picker> ~
					<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd HH:mm:ss" default-time='23:59:59'></el-date-picker>
				</div>
			</div>
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">推手级别: </label>
					<el-select v-model="AgentRoleId" style="width: 150px;margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in AgentRolelist" :key="item.AgentRoleId" :label="item.AgentRoleName" :value="item.AgentRoleId">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">锁粉店员: </label>
					<el-select v-model="lockassistant" style="width: 150px;margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option label="无锁粉店员" :value="0"></el-option>
						<el-option v-for="item in lockassistantlist" :key="item.Id" :label="item.EmployeeName" :value="item.Id">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">加入方式: </label>
					<el-select v-model="AgentAuthType" style="width: 150px;margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in AgentAuthlist" :key="item.id" :label="item.value" :value="item.id">
						</el-option>
					</el-select>

					<el-button type="primary" @click="handlefilter" style="margin-right:12px;margin-left: 15px;">查询</el-button>
					<buttonPermissions :datas="'AgentaddMemberBtn'">
						<el-button type="primary" @click="AddMember">添加推手</el-button>
					</buttonPermissions>

					<buttonPermissions :datas="'AgentaddMemberBtn'">
						<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
					</buttonPermissions>


				</div>
			</div>
		</el-card>

		<el-card style="margin-top:10px">
			<div class="table-container" style="width: 100%;margin-bottom: 15px;padding-bottom: 50px;">
				<el-checkbox v-model="IsNoSuper" :label="true" style="margin-bottom:10px" @change="Agentmanagelist">筛选没有上级的推手</el-checkbox>
				<el-table :data="AgentlistData" style="width: 100%;" v-loading="loading" ref="multipleTable">
					<el-table-column label="推手" width="180">
						<template slot-scope="scope">
							<div class="productInfo">
								<div class="Imgs">
									<img v-if="scope.row.WxHeadUrl" :src="scope.row.WxHeadUrl">
									<img v-else :src="defaultHeader">
								</div>
								<div class="Contains" style="margin-left: 8px;">
									<div class="top" style="display:flex">
										<div class="overFlow">
											{{scope.row.Name?scope.row.Name:scope.row.WxNickname}}
										</div>
									</div>
									<div class="flexRow" style="margin-top: 8px;">{{scope.row.Phone}}
										<eyes-hide v-model="scope.row.Phone" :data="{MemberId:scope.row.Id}"></eyes-hide>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column key="1.5" prop="InviteNo" label="邀请码" v-if="RecruitmentType==1||DistributorLockFansType==1">
						<template slot-scope="scope">
							{{scope.row.InviteNo?scope.row.InviteNo:'--'}}
						</template>
					</el-table-column>
					<el-table-column label="推手级别" prop="AgentRoleName" width="110"></el-table-column>
					<el-table-column prop="AgentAuthTypeValue" label="加入方式" min-width="150" key="AgentAuthTypeValue"></el-table-column>
					<el-table-column label="上级">
						<template slot-scope="scope">
							<div v-if='scope.row.AgentSuperName || scope.row.AgentSuperWxNickname || scope.row.AgentSuperPhone'>
								<div>{{scope.row.AgentSuperName?scope.row.AgentSuperName:scope.row.AgentSuperWxNickname}}</div>
								<div>{{scope.row.AgentSuperPhone}}</div>
							</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column label="推荐人" prop="Point">
						<template slot-scope="scope">
							<div v-if='scope.row.AgentRecommendName || scope.row.AgentRecommendWxNickname || scope.row.AgentRecommendPhone'>
								<div>{{scope.row.AgentRecommendName?scope.row.AgentRecommendName:scope.row.AgentRecommendWxNickname}}</div>
								<div v-if='scope.row.AgentRecommendPhone'>{{scope.row.AgentRecommendPhone}}</div>
							</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column label="团队人数" prop="LevelTeamCount">
						<template slot-scope="scope">
							<div style="color:#409EFF;cursor: pointer;" v-if='scope.row.LevelTeamCount>0' @click='TeamTable(scope.row,1)'>{{scope.row.LevelTeamCount}}</div>
							<div v-else>{{scope.row.LevelTeamCount}}</div>
						</template>
					</el-table-column>
					<el-table-column label="累计推荐" prop="RecommendCount">
						<template slot-scope="scope">
							<div style="color:#409EFF;cursor: pointer;" v-if='scope.row.RecommendCount>0' @click='TeamTable(scope.row,2)'>{{scope.row.RecommendCount}}</div>
							<div v-else>{{scope.row.RecommendCount}}</div>
						</template>
					</el-table-column>
					<el-table-column label="锁粉客户" prop="FansCount">
						<template slot-scope="scope">
							<div style="color:#409EFF;cursor: pointer;" v-if='scope.row.FansCount>0' @click='TeamTable(scope.row,3)'>{{scope.row.FansCount}}</div>
							<div v-else>{{scope.row.FansCount}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="AgentAuthTime" width="180" label="授权时间"></el-table-column>
					<el-table-column label="操作" width="240">
						<template slot-scope="scope">
							<!-- AgentRoleGradeNum == 1  最高等级 -->
							<buttonPermissions :datas="'Editagentsuper'" class="margin-right-10">
								<el-button type="text" v-if='scope.row.AgentRoleGradeNum != 1' @click="Editsuper(scope.row,1)">修改上级</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'Editagentrefer'" class="margin-right-10">
								<el-button type="text" @click="Editsuper(scope.row,2)" v-if='scope.row.AgentRoleGradeNum == 1'>修改推荐人</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'agentmanagelistUp'" class="margin-right-10" v-if="scope.row.AgentRoleGradeNum != 1">
								<el-button type="text" @click="toUp(scope.row)">升一级</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'CancelAgentauth'" class="margin-right-10">
								<el-button type="text" @click="Cancelauth(scope.row)" v-if='scope.row.AgentRoleGradeNum == 1'>取消授权</el-button>
							</buttonPermissions>
							<el-dropdown trigger="click" style="margin-right: 10px;" v-if='scope.row.AgentRoleGradeNum != 1'>
								<span class="el-dropdown-link" style="color:#409EFF;cursor: pointer;">
									更多
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item>
										<buttonPermissions :datas="'Editagentrefer'">
											<el-button type="text" @click="Editsuper(scope.row,2)">修改推荐人</el-button>
										</buttonPermissions>
									</el-dropdown-item>
									<el-dropdown-item>
										<buttonPermissions :datas="'CancelAgentauth'">
											<el-button type="text" @click="Cancelauth(scope.row)">取消授权</el-button>
										</buttonPermissions>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="Total">
				</el-pagination>
			</div>
		</el-card>
		<!-- //添加推手 -->
		<el-dialog title="添加推手" :visible.sync="UserInfoVisi" width="700px" class="eidt_info">
			<el-form :model="UserInfoForm" :rules="userRules" ref="UserInfoForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="姓名:" prop="Name">
					<el-input v-model="UserInfoForm.Name" maxlength="20" placeholder="最多输入20个汉字" style="width: 360px;"></el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="Phone">
					<el-input v-model="UserInfoForm.Phone" maxlength="11" placeholder="请输入手机号" style="width: 320px;"></el-input>
				</el-form-item>
				<el-form-item label="推手级别:" prop="RoleId">
					<el-select v-model="UserInfoForm.RoleId" placeholder="请选择" @change="selectAgentGradeNum">
						<el-option v-for="item in AgentRolelist" :key="item.AgentRoleId" :label="item.AgentRoleName" :value="item.AgentRoleId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="直接上级:" v-if='AgentGradeNum!=1&&AgentGradeNum!=null' prop="Directsuperphone">
					<div style="display: flex;">
						<div>
							<el-input v-model="UserInfoForm.Directsuperphone" style="width: 300px;" maxlength="11" clearable @clear="clearAddmember(1)"></el-input>
							<div v-if='UserInfoForm.SuperId' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.Name?EmployneeInfo.Name:EmployneeInfo.WxNickname}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.AgentRoleName}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsSearch' style="color:#E51C23;">查询不到相关推手数据，请确认输入手机号无误</div>

						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(1)">查询</el-button>
					</div>
				</el-form-item>

				<el-form-item label="推荐人:" prop="RecommdPhone">
					<div style="display: flex;">
						<div>
							<el-input v-model="UserInfoForm.RecommdPhone" style="width: 300px;" maxlength="11" clearable @clear="clearAddmember(2)"></el-input>
							<div v-if='UserInfoForm.RecommendId' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="RecommdInfo.WxHeadUrl?RecommdInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{RecommdInfo.Name?RecommdInfo.Name:RecommdInfo.WxNickname}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{RecommdInfo.AgentRoleName}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsRecommdSearch' style="color:#E51C23;">查询不到相关推手数据，请确认输入手机号无误</div>
						</div>
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(2)">查询</el-button>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="UserInfoVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveUserInfo('UserInfoForm')" :loading="SureAddloading">确认添加</el-button>
			</div>

		</el-dialog>

		<!-- //修改上级 推荐人 -->
		<el-dialog :title="EditsuperTitle" :visible.sync="EditsuperVisi" width="700px" class="eidt_info" v-loading='Editsuperloading'>
			<div style="display: flex">
				<label>{{EditsuperTitle}}</label>
				<div style="display: flex;margin-left: 25px;">
					<div>
						<el-input v-model="EditsuperPhone" style="width: 300px;" maxlength="11" clearable @clear="clearAddmember(3)"></el-input>
						<div v-if='EditsupercommonId' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
							<img :src="EditsuperInfo.WxHeadUrl?EditsuperInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
							<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
								<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EditsuperInfo.Name?EditsuperInfo.Name:EditsuperInfo.WxNickname}}</div>
								<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EditsuperInfo.AgentRoleName}}</div>
							</div>
							<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
								<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
							</div>
						</div>
						<div v-if='IscommonSearch' style="color:#E51C23;">查询不到相关推手数据，请确认输入手机号无误</div>
					</div>
					<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(3)">查询</el-button>
				</div>
			</div>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="EditsuperVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveEditsuperInfo" :loading="SureEditsuperloading">确认修改</el-button>
			</div>
		</el-dialog>

		<!-- //三个统计弹窗 -->
		<el-dialog :title="AgentNameTitle" :visible.sync="TeamtableVisi" width="960px" class="eidt_info">
			<div v-if="TypetableIndex == 1" style="display: flex;margin-bottom: 18px;">
				<div>共计:{{TeamArrayTotal}}人</div>
				<div v-for="(item,index) in TeamInfo" :key='index' style="margin-left: 15px;">
					<span>{{item.AgentRoleName}}：</span> <span>{{item.AgentCount}}人</span>
				</div>
			</div>
			<div v-if="TypetableIndex == 2" style="margin-bottom: 18px;">共计：{{RecommendCount}}人 直接推荐：{{recommendInfo.DirectRecommendCount}}人
				间接推荐：{{recommendInfo.InDirectRecommendCount}}人</div>
			<div v-if="TypetableIndex == 3" style="margin-bottom: 18px;">共计：{{lockInfo.AllCount}}人 待转化：{{lockInfo.WaitConvertCount}}人
				已转化：{{lockInfo.HaveConvertCount}}人
				已失效：{{lockInfo.HaveFailCount}}人</div>

			<div class="filter-container" style="margin-bottom: 15px;">
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="TeamsearchKey" placeholder="手机号、微信昵称、姓名" @keyup.enter.native="handlefilter1" style="width: 250px;;margin-right: 10px;"
					 clearable></el-input>
				</div>
				<div class="filter-item" v-if='TypetableIndex == 1'>
					<label class="label">推手级别: </label>
					<el-select v-model="AgentTeamRoleId" style="width: 150px;margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in AgentRolelist1" :key="item.AgentRoleId" :label="item.AgentRoleName" :value="item.AgentRoleId">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item" v-if='TypetableIndex == 2'>
					<label class="label">推荐关系: </label>
					<el-select v-model="RecommendLevel" style="width: 150px;margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in RecommendLevellist" :key="item.Id" :label="item.Value" :value="item.Id">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item" v-if='TypetableIndex == 3'>
					<label class="label">锁粉状态: </label>
					<el-select v-model="FansType" style="width: 150px;margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in FansTypelist" :key="item.Id" :label="item.Value" :value="item.Id">
						</el-option>
					</el-select>

				</div>
				<div class="filter-item">
					<el-button type="primary" @click='handlefilter1'>查询</el-button>
				</div>

			</div>
			<div class="table-container" style="width: 100%;margin-bottom: 15px;padding-bottom: 50px;">
				<el-table :data="AgentTeamData" style="width:100%;" v-loading="loading" ref="multipleTable">
					<el-table-column label="推手" width="180" :key='1'>
						<template slot-scope="scope">
							<div class="productInfo">
								<div class="Imgs">
									<img v-if="scope.row.WxHeadUrl" :src="scope.row.WxHeadUrl">
									<img v-else :src="defaultHeader">
								</div>
								<div class="Contains" style="margin-left: 8px;">
									<div class="top" style="display:flex">
										<div class="overFlow">
											{{scope.row.Name?scope.row.Name:scope.row.WxNickname}}
										</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="手机号" prop="Phone" :key='2'></el-table-column>
					<el-table-column label="推手级别" prop="AgentRoleName" :key='3' v-if='TypetableIndex==1'></el-table-column>
					<el-table-column label="推荐关系" prop="RecommendType" :key='4' v-if='TypetableIndex==2'></el-table-column>
					<el-table-column label="锁粉状态" prop="FansTypeValue" :key='5' v-if='TypetableIndex==3'></el-table-column>
					<el-table-column prop="AgentAuthTime" label="授权时间" :key='6' v-if="TypetableIndex==1"></el-table-column>
					<el-table-column prop="AgentRecommendTime" label="推荐时间" :key='8' v-if='TypetableIndex==2'></el-table-column>
					<el-table-column prop="FirstFromTime" label="锁粉时间"v-if='TypetableIndex==3'></el-table-column>
					<el-table-column label="到期时间" :key='9' >
						<template slot-scope="scope">
								{{scope.row.FirstFromExpireTimeValue}}
						</template>
					</el-table-column>

				</el-table>
				<el-pagination v-if="Total1" style="margin:20px 0;float:right;" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
				 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total1">
				</el-pagination>
			</div>
		</el-dialog>

		<!-- //取消授权 -->
		<el-dialog title="取消授权" :visible.sync="authorizeVisi" width="600px" class="eidt_info" v-loading='CanceloadingInfo'>
			<div class="cancelStylecommon">
				<div>{{AgentNameVisible}}<span v-if="AgentPhoneVisible">({{AgentPhoneVisible}})</span>账户上目前还存在</div>
				<div>(1)&nbsp;&nbsp;待结算收益{{CancelauthInfo.UnfinishBalance}}元</div>
				<div>(2)&nbsp;&nbsp;提现中收益{{CancelauthInfo.DrawingBalance}}元</div>
				<div>(3)&nbsp;&nbsp;已结算，可提现收益{{CancelauthInfo.WaitDrawBalance}}元</div>
				<div>(4)&nbsp;&nbsp;直接推荐推手{{CancelauthInfo.RecommendCount}}人</div>
				<div>(5)&nbsp;&nbsp;直属下级{{CancelauthInfo.LowerLevelCount}}人</div>
			</div>

			<div style="font-size: 14px;width: 80%;line-height: 22px;">若直接取消授权，提现中收益可正常审核，{{IsOpenTaoKeDistributorModel?'淘客订单待结算收益、':'待结算收益不再结算，'}}已结算收益不受影响；由{{AgentNameVisible}}推荐的推手，直接推荐人信息将清空；{{AgentNameVisible}}的直属下级将自动归到{{AgentNameVisible}}的直接上级团队内。</div>
			<div style="margin-top:6px;">是否确认继续取消{{AgentNameVisible}}授权？</div>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="authorizeVisi=false">关闭</el-button>
				<el-button type="primary" style="margin-left:25px;" @click="SureCancelAuth" :loading="SureCancelloading">确认取消授权</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import config from '@/config/index';
	import axios from 'axios'
	import buttonPermissions from '@/components/buttonPermissions';
	import eyesHide from "@/components/eyesHide"
	import {
		Agentmanagelist,
		AgentfilterBoxList,
		memberinfobyMemberId,
		addAgentSave,
		AgentLevelTeamManager,
		AgentLevelTeamEachRoleCount,
		AgentRecommendTeam,
		AgentLockFansPageInit,
		AgentLockFansTypeCount,
		AgentCancelConfirmData,
		AgentCancelAuth,
		AgentSuperInfo,
		AgentRecommendInfo,
		AgentSuperEditConfirmData,
		AgentSuperEdit,
		AgentRecommendEdit
	} from '@/api/TurnTomySelf.js';
	import {
		mapGetters
	} from 'vuex'
	import {
		employeelistbystate
	} from "@/api/sv1.0.0"
	import {
		taoKememberUpRoleGradeTip,
		taoKechangeMemberUpRoleGrade
	} from "@/api/sv3.0.0"
	export default {
		components: {
			buttonPermissions,
			eyesHide
		},
		computed: {
			...mapGetters([
				'RecruitmentType',
				'DistributorLockFansType',
				'IsOpenTaoKeDistributorModel'
			]),
		},
		data() {
			return {
				SureAddloading: false,
				exportUrl: config.EXPORT_URL,
				defaultHeader: config.DEFAULT_HEADER,
				loading: false,
				searchKey: '',
				searchType: 0,
				goodsSelect: [{
					value: '推手信息',
					id: 0
				}, {
					value: '推手上级',
					id: 1
				}, {
					value: '推手直接推荐人',
					id: 2
				}, {
					value: '最高级别推手',
					id: 3
				}],
				starTime: '',
				endTime: '',
				AgentRoleId: null,
				AgentRolelist: [],
				AgentAuthType: null,
				AgentAuthlist: [{
					value: '购买授权礼包商品',
					id: 1
				}, {
					value: '累计完成交易',
					id: 4
				}, {
					value: '无门槛授权',
					id: 3
				},{
					value: '余额单笔充值',
					id: 5
				}, {
					value: '手动添加',
					id: 2
				}],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				AgentlistData: [],
				//添加推手
				UserInfoVisi: false,
				IsRecommdSearch: false,
				IsSearch: false,
				UserInfoForm: {
					Name: '',
					Phone: '',
					RoleId: '',
					SuperId: '',
					RecommendId: '',
					Directsuperphone: '',
					RecommdPhone: ''
				},
				userRules: {
					Name: [{
						required: true,
						message: '请输入推手姓名',
						trigger: 'blur'
					}, {
						min: 1,
						max: 20,
						message: '最多输入20个字',
						trigger: 'blur'
					}],
					Phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						min: 1,
						max: 11,
						message: '最多输入11个字',
						trigger: 'blur'
					}],
					RoleId: [{
						required: true,
						message: '请选择推手级别',
						trigger: 'change'
					}]
				},
				AgentGradeNum: null, //选择的推手级别
				//直接上级推手信息
				EmployneeInfo: {},
				//推荐人推手信息
				RecommdInfo: {},
				oldDirectsuperphone: '',
				oldRecommdPhone: '', //验证是否点击查询
				EditsuperVisi: false,
				EditsuperTitle: '修改上级',
				//修改推荐人/上级
				EditsuperPhone: '',
				EditsupercommonId: '',
				IscommonSearch: false,
				EditsuperInfo: {}, //查询的信息
				EditsuperIndex: null,
				SureEditsuperloading: false,
				oldCommonphone: '',
				Editsuperloading: false,

				//数据弹窗
				TeamtableVisi: false,
				AgentNameTitle: '',
				AgentTeamData: [],
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				RecommendLevel: null,
				RecommendLevellist: [{
					Id: 1,
					Value: '直接推荐'
				}, {
					Id: 2,
					Value: '间接推荐'
				}],
				FansType: null,
				FansTypelist: [{
					Id: 1,
					Value: '待转化'
				}, {
					Id: 2,
					Value: '已转化'
				}, {
					Id: 3,
					Value: '已失效'
				}],
				TypetableIndex: null,
				MemberId: null,
				TeamsearchKey: '',
				AgentTeamRoleId: null,
				AgentRolelist1: [],
				RecommendCount: null,
				// {
				// 	AgentRoleName: '金金',
				// 	AgentCount: 10
				// }, {
				// 	AgentRoleName: '隐隐',
				// 	AgentCount: 20
				// }
				TeamInfo: [],
				TeamArrayTotal: 0,
				recommendInfo: {},
				lockInfo: {},
				//取消授权
				authorizeVisi: false,
				CancelauthInfo: {},
				AgentNameVisible: '',
				AgentPhoneVisible: '',
				SureCancelloading: false,
				CanceloadingInfo: false,
				//上级确认数据
				// EditsuperTitlephone: ''

				lockassistantlist:[],
				lockassistant:null,
				IsNoSuper:false
			}
		},
		created() {
			this.getagentRole()
			this.getlockassistantlist()
		},
		beforeMount() {
			if(this.$route.query.msg){
				// console.log(this.$route.query.msg)
				this.searchKey = this.$route.query.msg
			}
			this.Agentmanagelist()
		},
		methods: {
			async toUp(row){
				try{
					this.loading = true
					let superiors = await taoKememberUpRoleGradeTip({
						MemberId:row.Id
					})
					if(superiors.IsSuccess){
						const h = this.$createElement;
						this.$msgbox({
							title: '提示',
							message: h('div', {class:'font-14 coloc-606266',style:'line-height:1.5'}, [
								h('div', null, `推手【${superiors.Result.MemberName}-${superiors.Result.MemberPhone}】当前级别【${superiors.Result.AgentRoleName}】，升级后，更新以下信息：`),
								h('ul',{style:'margin:5px 10px;padding-left:15px'},[
									h('li',{class:'color-f56c6c'},[
										h('span',null,'级别'),
										h('span',{class:'color-606266'},`升级为【${superiors.Result.UpAgentRoleName}】`),
									]),
									h('li',{class:'color-f56c6c',style:{'display':superiors.Result.UpRecommendName?'block':'none'}},[
										h('span',null,'直接推荐人'),
										h('span',{class:'color-606266'},`为【${superiors.Result.UpRecommendName}】`),
									]),
									h('li',{class:'color-f56c6c'},[
										h('span',null,'新上级'),
										h('span',{class:'color-606266'},`为【${superiors.Result.UpSuperName||'官方'}】`),
									])
								]),
								h('div',null,'升级后不可撤销，是否确认升级？')
							]),
							showCancelButton: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type:'warning'
						}).then(async () => {
							try{
								let res = await taoKechangeMemberUpRoleGrade({
									MemberId:row.Id
								})
								if(res.IsSuccess){
									this.$message.success('操作成功')
									this.Agentmanagelist()
								}
							}finally{
								//
							}
						});
					
					}
				}finally{
					this.loading = false
				}
			},
			async getlockassistantlist(){
				try{
					let res = await employeelistbystate({
						EmployeeState:-1,
						ShopId:null,
					})
					if(res.IsSuccess){
						this.lockassistantlist = res.Result
					}
				}finally{
					//
				}
			},
			//取消授权
			async Cancelauth(record) {
				try {
					this.CanceloadingInfo = true
					this.authorizeVisi = true
					this.AgentNameVisible = record.Name ? record.Name : record.WxNickname
					this.AgentPhoneVisible = record.Phone
					this.MemberId = record.Id
					let data = {
						MemberId: record.Id

					}
					let result = await AgentCancelConfirmData(data)
					if (result.IsSuccess) {
						this.CancelauthInfo = result.Result
					}
				} catch (err) {

				} finally {
					this.CanceloadingInfo = false
				}
				// this.authorizeVisi = true

			},
			//确认取消授权
			async SureCancelAuth() {
				try {
					this.SureCancelloading = true
					let data = {
						MemberId: this.MemberId
					}
					let result = await AgentCancelAuth(data)
					if (result.IsSuccess) {
						this.authorizeVisi = false
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.Agentmanagelist()

					}
				} catch (err) {

				} finally {
					this.SureCancelloading = false
				}
			},
			handlefilter1() {
				this.currentPage1 = 1
				this.getChangeTablelist()
			},
			//点击团队人数
			TeamTable(record, index) {
				// 1 团队    2推荐  3锁粉
				this.TypetableIndex = index
				this.MemberId = record.Id
				this.currentPage1 = 1
				this.TeamtableVisi = true
				this.TeamsearchKey = ''
				if (this.TypetableIndex == 1) {
					this.AgentNameTitle = (record.Name ? record.Name : record.WxNickname) + '的团队'

					this.AgentRolelist1 = this.AgentRolelist.filter(item => {
						return record.AgentRoleGradeNum < item.AgentRoleGradeNum
					})
					this.AgentTeamRoleId = null

				} else if (this.TypetableIndex == 2) {
					this.AgentNameTitle = (record.Name ? record.Name : record.WxNickname) + '的推荐'
					this.RecommendLevel = null


				} else {
					this.AgentNameTitle = (record.Name ? record.Name : record.WxNickname) + '的锁粉客户'
					this.FansType = null

				}

				this.getChangeTablelist()
			},
			async getChangeTablelist() {
				try {
					this.loading = true
					let data = {
						MemberId:this.$route.query.id,
						KeyWords: this.TeamsearchKey,
						Skip: (this.currentPage1 - 1) * this.pageSize1, // 略过数据
						Take: this.pageSize1, // 取的数据
					}
					//团队的请求
					if (this.TypetableIndex == 1) {
						data.Id = this.MemberId
						data.AgentRoleId = this.AgentTeamRoleId
						let result = await AgentLevelTeamManager(data)
						let result1 = await AgentLevelTeamEachRoleCount({
							MemberId: this.MemberId
						})
						if (result.IsSuccess) {
							this.AgentTeamData = result.Result.Results || [];
							this.Total1 = result.Result.Total;
						}
						if (result1.IsSuccess) {
							if (result1.Result.length) {
								let sum = 0
								result1.Result.forEach(item => {
									sum += item.AgentCount
								})
								this.TeamArrayTotal = sum
								this.TeamInfo = result1.Result
							}
							// let sum = 0
							// this.TeamInfo.forEach(item => {
							// 	sum += item.AgentCount
							// })
						}
					}
					//推荐关系
					if (this.TypetableIndex == 2) {
						data.Id = this.MemberId
						data.RecommendLevel = this.RecommendLevel
						let result = await AgentRecommendTeam(data)
						if (result.IsSuccess) {
							this.RecommendCount = result.Result.RecommendCount
							this.recommendInfo.DirectRecommendCount = result.Result.DirectRecommendCount
							this.recommendInfo.InDirectRecommendCount = result.Result.InDirectRecommendCount
							this.AgentTeamData = result.Result.Results || [];
							this.Total1 = result.Result.Total;
						}
					}
					//锁粉关系
					if (this.TypetableIndex == 3) {
						data.MemberId = this.MemberId
						data.FansType = this.FansType
						let result = await AgentLockFansPageInit(data)
						let result1 = await AgentLockFansTypeCount({
							MemberId: this.MemberId
						})
						if (result.IsSuccess) {
							this.AgentTeamData = result.Result.Results || [];
							this.Total1 = result.Result.Total;
						}
						if (result1.IsSuccess) {
							this.lockInfo = result1.Result
							// console.log(this.lockInfo,'这个锁粉信息不对吗')
						}
					}


				} catch (err) {
					// this.loading = false
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getChangeTablelist();
			},

			// 翻页
			handleCurrentChange1(val) {
				this.currentPage1 = val;
				this.getChangeTablelist();
			},
			//修改上级、推荐人
			Editsuper(record, index) {
				this.EditsuperIndex = index //区分上级和推荐人
				this.MemberId = record.Id
				this.AgentNameVisible = record.Name ? record.Name : record.WxNickname
				this.AgentPhoneVisible = record.Phone
				// index 1 上级    2推荐人
				this.EditsuperVisi = true
				if (index == 1) {
					this.EditsuperTitle = '修改上级'
				} else {
					this.EditsuperTitle = '修改直接推荐人'
				}
				//获取上级、推荐人信息
				this.getSuperInfo()
			},
			//确认修改
			async SaveEditsuperInfo() {
				try {
					this.SureEditsuperloading = true
					//上级
					// console.log(this.EditsuperPhone, '输入的手机号')
					// console.log(this.oldCommonphone, '之前输入的手机号')
					if (this.EditsuperPhone != '' && this.EditsuperPhone != this.oldCommonphone) {
						// console.log(this.EditsuperPhone, '输入的手机号')
						if (this.EditsuperIndex == 1) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请点击[查询]确认直接上级信息'
							});
							return
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请点击[查询]确认直接推荐人信息'
							});
							return
						}

					}

					let data = {
						MemberId: this.MemberId
					}
					// console.log(this.EditsuperIndex, '是上级还是直接推荐人')
					// let result;
					//直接上级确认数据
					if (this.EditsuperIndex == 1) {
						let result = await AgentSuperEditConfirmData(data)
						if (result.IsSuccess) {
							let message = this.AgentNameVisible + (this.AgentPhoneVisible ? ('(' + this.AgentPhoneVisible + ')') : '') +
								'目前直接推荐推手' + result.Result.DirectRecommendCount + '人，间接推荐<br/>推手' +
								result.Result.InDirectRecommendCount + '人' + '<br/>上级修改成功之后，将自动打断' + this.AgentNameVisible + '与平级推荐推手的关系' +
								'<br/>该操作不可逆，请确认是否继续修改' + this.AgentNameVisible + '的上级？'
							this.$confirm(message, '', {
								confirmButtonText: '确认修改上级',
								cancelButtonText: '关闭',
								dangerouslyUseHTMLString: true
							}).then(async () => {
								let result = await AgentSuperEdit({
									MemberId: this.MemberId,
									SuperId: this.EditsupercommonId
								})

								if (result.IsSuccess) {
									this.EditsuperVisi = false
									this.$message({
										showClose: true,
										type: 'success',
										message: '操作成功!'
									});
								}

							}).catch(() => {
								this.$message({
									showClose: true,
									type: 'info',
									message: '已取消操作'
								});
							}).finally(() => {
								this.Agentmanagelist();
							})
							// this.SuperEditConfirmData = result.Result
						}
					}
					//直接推荐人确认数据
					else {
						// console.log('直接推荐人没有执行吗')
						this.SureEditrecommd()
					}
				} catch (err) {

				} finally {
					this.SureEditsuperloading = false
				}
			},
			//确认修改直接推荐人
			async SureEditrecommd() {
				try {
					let data = {
						MemberId: this.MemberId,
						RecommendId: this.EditsupercommonId
					}
					let result = await AgentRecommendEdit(data)
					if (result.IsSuccess) {
						this.EditsuperVisi = false
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.Agentmanagelist();
					}
				} catch (err) {

				} finally {

				}
			},
			async getSuperInfo() {
				try {
					this.Editsuperloading = true
					let data = {
						MemberId: this.MemberId
					}
					let result;
					if (this.EditsuperIndex == 1) {
						result = await AgentSuperInfo(data)
					} else if (this.EditsuperIndex == 2) {
						result = await AgentRecommendInfo(data)
					}
					if (result.IsSuccess) {
						if (result.Result.AgentRoleId) {
							this.EditsuperPhone = result.Result.Phone
							this.EditsupercommonId = result.Result.Id
							this.EditsuperInfo.Name = result.Result.Name ? result.Result.Name : result.Result.WxNickname
							this.EditsuperInfo.WxHeadUrl = result.Result.WxHeadUrl
							this.EditsuperInfo.AgentRoleName = result.Result.AgentRoleName
							this.oldCommonphone = result.Result.Phone
							this.IscommonSearch = false

						} else {
							this.EditsuperPhone = ''
							this.EditsupercommonId = 0
							this.EditsuperInfo.Name = ''
							this.EditsuperInfo.WxHeadUrl = ''
							this.EditsuperInfo.AgentRoleName = ''
							this.EditsuperInfo.WxNickname = ''
							this.oldCommonphone = result.Result.Phone
							this.IscommonSearch = false
						}
					}
				} catch (err) {

				} finally {
					this.Editsuperloading = false
				}
			},
			//通过手机号查询代理信息
			async SearchLock(index) {
				try {
					//index  1 直接上级   2 推荐人
					//查询的手机号不能为空
					if (index == 1) {
						if (!this.UserInfoForm.Directsuperphone) {
							this.$message({
								showClose: true,
								message: '请输入正确的手机号',
								type: 'error'
							});
							return
						}
					}
					if (index == 2) {
						if (!this.UserInfoForm.RecommdPhone) {
							this.$message({
								showClose: true,
								message: '请输入正确的手机号',
								type: 'error'
							});
							return
						}
					}

					//上级、推荐的修改
					if (index == 3) {
						if (!this.EditsuperPhone) {
							this.$message({
								showClose: true,
								message: '请输入正确的手机号',
								type: 'error'
							});
							return
						}
					}
					let data;
					if (index == 1) {
						data = {
							Phone: this.UserInfoForm.Directsuperphone,
							MemberId: null
						}
					}
					if (index == 2) {
						data = {
							Phone: this.UserInfoForm.RecommdPhone,
							MemberId: null,
						}
					}
					//上级、推荐的修改
					if (index == 3) {
						data = {
							Phone: this.EditsuperPhone,
							MemberId: null,
						}
					}
					let result = await memberinfobyMemberId(data)
					if (result.IsSuccess) {
						if (index == 1) {
							//查询的等级比选择级别低
							// if (result.Result.AgentRoleGradeNum >= this.AgentGradeNum) {
							// 	this.$message({
							// 		showClose: true,
							// 		message: '直接上级的级别需要高于要添加的推手级别',
							// 		type: 'error'
							// 	});
							// 	return
							// }
							//没有查询到数据
							if (result.Result.AgentRoleId == 0) {
								this.IsSearch = true
								return
							} else {
								this.IsSearch = false
							}
							this.EmployneeInfo = result.Result
							this.UserInfoForm.SuperId = this.EmployneeInfo.Id
							this.oldDirectsuperphone = this.UserInfoForm.Directsuperphone
						}
						if (index == 2) {
							//查询的推荐人级别与选择的代理级别不相等
							// if (result.Result.AgentRoleGradeNum != this.AgentGradeNum && result.Result.AgentRoleGradeNum != 0) {
							// 	this.$message({
							// 		showClose: true,
							// 		message: '直接推荐人的级别需和添加的推手同一级别',
							// 		type: 'error'
							// 	});
							// 	return
							// }
							// //查询
							// if (this.UserInfoForm.SuperId && result.Result.AgentSuperId != this.UserInfoForm.SuperId) {
							// 	this.$message({
							// 		showClose: true,
							// 		message: '推手与平级推荐人需要同属于一个上级',
							// 		type: 'error'
							// 	});
							// 	return
							// }
							//没有查询到数据
							if (result.Result.AgentRoleId == 0) {
								this.IsRecommdSearch = true
								return
							} else {
								this.IsRecommdSearch = false
							}
							this.RecommdInfo = result.Result
							this.UserInfoForm.RecommendId = this.RecommdInfo.Id
							this.oldRecommdPhone = this.UserInfoForm.RecommdPhone
						}
						//修改上级和推荐人
						if (index == 3) {
							//没有查询到数据
							if (result.Result.AgentRoleId == 0) {
								this.IscommonSearch = true
								return
							} else {
								this.IscommonSearch = false
							}
							this.EditsuperInfo = result.Result
							this.EditsupercommonId = this.EditsuperInfo.Id
							this.oldCommonphone = this.EditsuperPhone
						}
					}


				} catch (err) {

				} finally {

				}
			},
			//获取选择的推手级别
			selectAgentGradeNum() {
				this.AgentRolelist.map(item => {
					if (item.AgentRoleId == this.UserInfoForm.RoleId) {
						this.AgentGradeNum = item.AgentRoleGradeNum
					}
				})
				//选择了最高级别
				if (this.AgentGradeNum == 1) {
					this.UserInfoForm.SuperId = 0
					this.EmployneeInfo = {}
					this.IsSearch = false
				}
				// console.log(this.AgentGradeNum,'选择的推手级别')
			},
			//清空输入
			clearAddmember(index) {
				// index 1 直接上级   2直接推荐人
				if (index == 1) {
					if (this.UserInfoForm.SuperId) {
						this.EmployneeInfo = {}
						this.UserInfoForm.SuperId = 0
					}
					if (this.IsSearch) {
						this.IsSearch = false
					}
				}

				if (index == 2) {
					if (this.UserInfoForm.RecommendId) {
						this.recommendInfo = {}
						this.UserInfoForm.RecommendId = 0
					}
					if (this.IsRecommdSearch) {
						this.IsRecommdSearch = false
					}
				}
				//修改上级、推荐人
				if (index == 3) {
					if (this.EditsupercommonId) {
						this.EditsuperInfo = {}
						this.EditsupercommonId = 0
					}
					if (this.IscommonSearch) {
						this.IscommonSearch = false
					}
				}

			},
			//保存推手信息
			SaveUserInfo(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.SureAddloading = true
						try {
							//不是第一等级
							if (this.AgentGradeNum != 1) {
								if (this.UserInfoForm.Directsuperphone != '' && this.UserInfoForm.Directsuperphone != this.oldDirectsuperphone) {
									this.$message({
										showClose: true,
										type: 'error',
										message: '请点击[查询]确认直接上级信息'
									});
									return
								}

								if (this.IsSearch) {
									this.$message({
										showClose: true,
										type: 'error',
										message: '请输入正确的推手信息'
									});
									return false
								}
							}
							if (this.UserInfoForm.RecommdPhone != '' && this.UserInfoForm.RecommdPhone != this.oldRecommdPhone) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请点击[查询]确认直接推荐人信息'
								});
								return
							}

							if (this.IsRecommdSearch) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请输入正确的推手信息'
								});
								return false
							}
							const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
							if (!reg.test(this.UserInfoForm.Phone)) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请输入正确的手机号！'
								});
								return
							}
							let data = {
								Name: this.UserInfoForm.Name,
								Phone: this.UserInfoForm.Phone,
								RoleId: this.UserInfoForm.RoleId,
								SuperId: this.UserInfoForm.SuperId,
								RecommendId: this.UserInfoForm.RecommendId
							}
							let result = await addAgentSave(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.UserInfoVisi = false;
								this.Agentmanagelist()

							}
						} catch (err) {

						} finally {
							this.SureAddloading = false
						}
					} else {
						return false;
					}
				});
			},
			//导出
			async exportFun() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/agent/agentManagerExport?' +
						'&KeyWordsType=' + this.searchType +
						'&KeyWords=' + this.searchKey +
						'&AuthStartTime=' + (this.starTime ? this.starTime : '') +
						'&AuthEndTime=' + (this.endTime ? this.endTime : '') +
						'&AgentRoleId=' + (this.AgentRoleId ? this.AgentRoleId : '') +
						'&AgentAuthType=' + (this.AgentAuthType ? this.AgentAuthType : '') +
						'&LockMallEmployeeId=' + (this.lockassistant!=null? this.lockassistant : '') +
						'&IsNoSuper=' + this.IsNoSuper 

					// console.log(url,'网址1212121')
					window.open(url);
					// console.log("url",url)
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			//添加推手
			AddMember() {

				this.UserInfoVisi = true
				this.$nextTick(() => {
					this.$refs.UserInfoForm.resetFields()
				})
				//清空
				// this.UserInfoForm.Directsuperphone = ''

				this.UserInfoForm.SuperId = 0
				this.IsSearch = false
				// this.UserInfoForm.RecommdPhone = ''
				this.UserInfoForm.RecommendId = 0
				this.IsRecommdSearch = false
			},
			//查询
			handlefilter() {
				this.currentPage = 1
				this.Agentmanagelist()
			},
			async getagentRole() {
				try {
					let result = await AgentfilterBoxList()
					if (result.IsSuccess) {
						this.AgentRolelist = result.Result
					}
				} catch (err) {

				} finally {

				}
			},
			async Agentmanagelist() {
				try {
					this.loading = true
					let data = {
						KeyWordsType: this.searchType,
						KeyWords: this.searchKey,
						AuthStartTime: this.starTime,
						AuthEndTime: this.endTime,
						AgentRoleId: this.AgentRoleId,
						AgentAuthType: this.AgentAuthType,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						LockMallEmployeeId:this.lockassistant,
						IsNoSuper:this.IsNoSuper
					}
					let result = await Agentmanagelist(data)
					if (result.IsSuccess) {
						this.AgentlistData = result.Result.Results || [];
						this.Total = result.Result.Total;
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},

			//修改推荐人
			Editrefer() {

			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.Agentmanagelist();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.Agentmanagelist();
			},

		}
	}
</script>
<style lang="less" scoped>
.flexRow{
	display: flex;
	align-items: center;
}
	.agentMangelist {
		background: #fff;
		padding: 13px;
	}

	.productInfo {
		display: flex;

		.Activestyle {
			font-size: 12px;
			background: #F56C6C;
			color: #fff;
			text-align: center;
			padding-left: 4px;
			padding-right: 4px;
			box-sizing: border-box;

		}
	}

	.productInfo .Imgs img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: none;
		object-fit: initial;
	}

	.productInfo .overFlow {
		width: 100px;
		margin-right: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.cancelStylecommon {
		font-size: 14px;

		div {
			margin-bottom: 13px;
		}

	}

	::v-deep .el-dialog__body {
		padding-top: 10px;
		padding-bottom: 30px;
	}
</style>
